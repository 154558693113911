window.Noty = require('noty');


$(document).ready(function () {
    $('.js-logout').on('click', function () {
        $('#logout-form').submit();
    });

    $('#register-modal').on('shown.bs.modal', function () {
        $('#login-modal').modal('hide');
    });
    $('#password-modal').on('shown.bs.modal', function () {
        $('#login-modal').modal('hide');
    });
    $('#login-modal').on('shown.bs.modal', function () {
        $('#register-modal').modal('hide');
        $('#member-modal').modal('hide');
    });

    $('.js-ticket-submit').on('click', function () {
        $('#js-ticket-form').submit();
    });


    $('.js-video-filter').on('change', function () {
        var apiUrl = "/api/videotar";
        var query = "";
        $(".js-video-filter").each(function () {
            if ($(this).is(':checked')) {
                query += $(this).attr('id') + '|';
            }
        });
        return alert(query);
        $.ajax({
            url: apiUrl,
            type: "GET",

            success: function (response) {
                console.log(response);
                if (response) {
                    $('.success').text(response.success);
                    $("#ajaxform")[0].reset();
                }
            },
        });

    });

});
